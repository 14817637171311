<template>
  <div class="mark-select-bg">
    <div class="select-content-bg" v-click-outside="hideModalOptions">
      <div class="select-content" @click="openModalOptions">
        <div class="select-label">Категория:</div>
        <div class="select-content-placeholder" v-if="!value.length">Выберите категорию</div>
        <div class="select-content-value-bg" v-if="value.length">
          <div class="select-content-value">
            {{ value[0].id }}
          </div>
          <div class="select-content-count" v-if="value.length > 1">+{{ value.length - 1 }}</div>
        </div>
        <i
          class="icon-up"
          :style="!showModal ? 'transform: rotate(180deg);' : ''"
          v-show="(value.length <= 1 && multiple) || (value.length < 1 && !multiple)"
        ></i>
        <i
          class="icon-cross"
          v-show="(value.length > 1 && multiple) || (value.length >= 1 && !multiple)"
          @click.self="deleteAllOption"
        ></i>
      </div>
      <ModalSelectCategory
        v-if="showModal"
        :value="value"
        :placeholder="placeholder"
        :optionData="optionData"
        :itemName="itemName"
        :entity="entity"
        :maxCount="maxCount"
        :multiple="multiple"
        :actionEntity="actionEntity"
        :hiddenItems="hiddenItems"
        :createMode="createMode"
        :createEntity="createEntity ? createEntity : 'actionCreate'"
        @pushOptionToValue="pushOptionToValue"
        @deleteOptionToValue="deleteOptionToValue"
        @pushAllOption="pushAllOption"
      />
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import ModalSelectCategory from "@/components/KnowledgeBaseComponents/Regulation/ModalSelectCategory";

export default {
  name: "CategoryPicker",
  mixins: [],
  props: [
    "entity",
    "actionEntity",
    "multiple",
    "placeholder",
    "itemName",
    "keyValue",
    "maxCount",
    "label",
    "editValue",
    "findId",
    "createMode",
    "hiddenItems",
    "createEntity",
    "isAdmin"
  ],
  components: { ModalSelectCategory },
  data() {
    return {
      value: [],
      optionData: [
        {
          id: "Инструкции",
          name: "instruction"
        },
        {
          id: "Памятки",
          name: "regulation"
        }
      ],
      showModal: false
    };
  },
  mounted() {
    if (this.isAdmin) {
      this.optionData.push({ id: "Гайды", name: "guide" });
    }
    setTimeout(() => {
      if (this.editValue && this.editValue.length) {
        this.value = this.editValue;
      }
    }, 0);
    this.$root.$on("hideSelectModal", () => {
      this.showModal = false;
    });
  },
  methods: {
    openModalOptions() {
      this.showModal = !this.showModal;
    },
    hideModalOptions() {
      this.showModal = false;
    },
    deleteAllOption() {
      event.stopPropagation();
      this.value = [];
    },
    pushAllOption(options) {
      this.value = JSON.parse(JSON.stringify(options));
    },
    pushOptionToValue(option) {
      if (this.multiple) {
        if (this.maxCount) {
          if (this.value.length < this.maxCount) {
            this.value.push(option);
          } else {
            this.$root.$emit("showNotification", {
              type: "error",
              timeout: 5000,
              label: `Максимальное кол-во ${this.maxCount} !`
            });
          }
        } else {
          this.value.push(option);
        }
      } else {
        if (this.value.length) {
          this.value.splice(0, 1);
          this.value.push(option);
        } else {
          this.value.push(option);
        }
      }
    },
    deleteOptionToValue(option) {
      let index = this.value.findIndex((val) => val.id === option.id);
      this.value.splice(index, 1);
    }
  },
  computed: {},
  watch: {
    value() {
      this.$root.$emit(`changeSelectValue:${this.keyValue}`, this.value);
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>
<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
.mark-select-bg {
  width: 100%;
  .select-label {
    margin-right: 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: $text-subdued;
  }
  .select-content-bg {
    position: relative;
    width: 100%;
    height: 30px;

    .select-content {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 12px 16px;
      background: #fafbfc;
      border-radius: 24px;
      cursor: pointer;

      .select-content-placeholder {
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $text-disabled;
      }
      .select-content-value-bg {
        display: flex;
        align-items: center;
        width: calc(100% - 95px);
        flex-shrink: 0;

        .select-content-value {
          font-weight: normal;
          font-size: 14px;
          line-height: 23px;
          color: $text-default;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .select-content-count {
          font-weight: normal;
          font-size: 12px;
          line-height: 13px;
          color: $text-default;
          margin: 0 6px 0 4px;
          background: #c1c7d0;
          padding: 2px 4px;
          border-radius: 14px;
        }
      }
      .icon-cross,
      .icon-up {
        color: $color-text-secondary;
        transition: 0.3s;
      }
    }
  }
}
</style>
